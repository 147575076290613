import React from "react";

const CDRateCalculatorDisclaimer = (props) => {
  return (
    <div className="sub-section text-sm">
      <div className="container px-0">
        <div className="row d-flex d-lg-none">
          <div className="col">
            <ul className="pl-3">
              <li id="apy-definition-disclaimer" className="text-muted">
                APY is Annual Percentage Yield. The above APY is offered at this time and subject to change. Actual
                earnings may vary.
              </li>
              <li id="penalty-advertisement-disclaimer" className="text-muted">
                A penalty may be imposed for withdrawals before maturity.
              </li>
              <li id="educational-purposes-only-disclaimer" className="text-muted">
                This calculator is for educational purposes only, and may not represent WaFd Bank's program
                requirements.
              </li>
            </ul>
          </div>
        </div>
        <div className="row d-none d-lg-flex">
          <div className="col-lg-6">
            <ul>
              <li id="apy-definition-disclaimer" className="text-muted">
                APY is Annual Percentage Yield. The above APY is offered at this time and subject to change. Actual
                earnings may vary.
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <ul>
              <li id="penalty-advertisement-disclaimer" className="text-muted">
                A penalty may be imposed for withdrawals before maturity.
              </li>
              <li id="educational-purposes-only-disclaimer" className="text-muted">
                This calculator is for educational purposes only, and may not represent WaFd Bank's program
                requirements.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CDRateCalculatorDisclaimer;
