export const getSetInputValue = (inputRef, functionData) => {
  /*
    You can send 3 different values for functionData

    1.-
      {
        event: inputEvent,
        inputTarget: inputTarget,
        newValue: "123,456"
      }
      In this case you will update the input you are writing on and move the cursor properly to keep typing.
    2.-
      {
        newValue: "123,456"
      }
      In this case will you type in an input you can update another input value with its right format too.
    3.-
      {}
      In this case you will get the current value of the input you want, in case the function memoized the state
      with an outdated value, you can trust you will get the real value with this.
  */
  // console.log("I DID SOMETHING FOR:", inputRef);
  const { event, inputTarget, newValue } = functionData;
  let inputValue = 0;
  if (inputRef.current) {
    inputValue = inputRef.current.getSetInputValue(event, inputTarget, newValue);
  }
  return inputValue;
};

export const getSetSelectValue = (selectRef, newValue) => {
  let selectValue = "";
  if (selectRef.current) {
    selectValue = selectRef.current.getSetSelectValue(newValue);
  }
  return selectValue;
};

export const getSetInputError = (inputRef, newValue) => {
  let inputErrors = "";
  if (inputRef.current) {
    inputErrors = inputRef.current.getSetError(newValue);
  }
  return inputErrors;
};

export const getSetValue = (inputRef, newValue) => {
  let inputValue = "";
  if (inputRef.current) {
    inputValue = inputRef.current.handleOnChange(newValue);
  }
  return inputValue;
};