import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql, Link } from "gatsby";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../../components/contact-info/contact-info";
import SEO from "../../../components/seo/seo";
import buildCdRow from "../../../helpers/buildCdRow";
import filterCdRecords from "../../../helpers/filterCdRecords";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";
import Button from "../../../components/custom-widgets/button";
import CDRateCalculator from "../../../components/calculators/cd-rate-calculator/cd-rate-calculator";
import FaqAccordion from "../../../components/faq/faq-accordion";
import CdCards from "../../../components/savings-overview/cd-cards";

const CDs = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiCdS {
        nodes {
          APY
          CostTier
          InterestRate
          ProductType
          RenewalTerm
          Term
          WithEligibleAccount
          id
        }
      }
      allStrapiProducts {
        nodes {
          Name
          ProductData {
            name
            value
          }
        }
      }
    }
  `);

  const cds = data.allStrapiCdS;

  const CDData = data.allStrapiProducts.nodes;

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/savings-account",
      title: "Savings, CDs & Money Markets"
    },
    {
      id: 3,
      active: true,
      title: "Certificates of Deposit (CDs)"
    }
  ];

  const title = "Open a CD (Certificate of Deposit) Account",
    description =
      "Use WaFd Bank's CD rate calculator & find out how much you can save. Open a CD (Certificate of Deposit) account today with terms ranging from 14 days to 5 years.";
  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/savings-account/certificate-of-deposit"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const CdListItems = {
    greenCircleCheckmark: true,
    accountName: "certificate-of-deposit",
    items: [
      {
        id: 1,
        text: "Only $1,000 initial deposit ($500 for minors, $100 for IRAs)"
      },
      {
        id: 2,
        text: "Standard terms are available from 14 days to 5 years (60 months)."
      }
    ]
  };

  const faqAccordionData = {
    id: "cd-calculator-faq-accordion",
    faqCategoryNames: ["CD Calculator"]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />

      {/* CD SPECIALS and RATES */}
      <section id="cd-rates" className="container pb-4">
        <div className="row">
          <div className="col">
            <h1>Certificates of Deposit (CD) Account</h1>
            <h3>
              Grow your money faster and safely with a Certificate of Deposit (CD). We'll help your money to work harder
              for you with an above average interest rate and a wide range of terms to choose from. Get started with
              just $1,000 ($500 for minors).<sup>1</sup>
            </h3>
            <Button
              id="wafd-cd-calculator-anchor-link"
              type="anchor-link"
              class="btn-link mt-2 mb-4"
              text="WaFd CD Calculator"
              url="#cd-rates-calculator"
              icon={{
                name: "calculator",
                class: "ml-1"
              }}
            />
          </div>
        </div>
        <div className="row">
          <CdCards />
        </div>
      </section>

      {/* CD vs SAVINGS CALCULATOR CHART */}
      <CDRateCalculator cdData={CDData} />

      {/* CD vs SAVINGS BLOG */}
      <section id="cd-vs-savings-blog-section" className="bg-blue-10 mb-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <StaticImage
                src="../../../images/thumbnail-hys-cds-02162023-730.jpg"
                alt="Happy senior couple banking on mobile phone."
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="col-md-6">
              <h2>High Yield Savings Accounts vs CD Accounts - Pros & Cons</h2>
              <p>
                You're probably familiar with savings accounts&mdash;after all, the only difference between a checking
                and a savings is that you usually don't get a debit card when you open a savings account. A certificate
                of deposit (CD) is intended for you to set aside your cash for a rainy day, just like a savings account,
                but with a few more rules.
              </p>
              <Link
                className="btn btn-primary w-100 w-sm-auto"
                id="cd-vs-savings-blog-cta"
                to="/blog/banking-101/cds-vs-savings-accounts"
              >
                Read Article
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* CD DETAILS LIST */}
      <section className="container py-0 pb-4">
        <div className="bg-light border-radius-12 p-3">
          <div className="row">
            <div className="col-md-6 col-lg-8 col-xl-9">
              <h2 className="text-success">Certificate of Deposit</h2>

              {CdListItems.items.map((cdListItem, idx) => {
                return (
                  <p
                    id={CdListItems.accountName ? `${CdListItems.accountName}-${cdListItem.id}` : `item-${idx + 1}`}
                    key={CdListItems.accountName ? `${CdListItems.accountName}-${cdListItem.id}` : `item-${idx + 1}`}
                  >
                    {cdListItem.text}
                  </p>
                );
              })}
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3 text-md-right">
              <Link to="/locations" id="apply-at-a-branch-cta" className="btn btn-link w-100 w-sm-auto">
                Apply at a Branch Near You
              </Link>
            </div>
          </div>
          <div className="table-responsive my-4">
            <table className="table table-bordered text-xs-xs mb-0">
              <thead className="bg-secondary text-white">
                <tr>
                  <th>Choice of Terms</th>
                  <th>$1,000 + Interest Rate/APY %</th>
                  <th>$90,000 + Interest Rate/APY %</th>
                  <th>Renewal Term</th>
                </tr>
              </thead>
              <tbody>
                {buildCdRow(filterCdRecords(cds, "14 Days - 2 Months", "14 Days - 2 Months"))}
                {buildCdRow(filterCdRecords(cds, "3 Months", "3 Months"))}
                {/* {buildCdRow(filterCdRecords(cds, "4 - 5 Months", "4 - 5 Months"))} */}
                <tr>
                  <td id="4-5months-term">4 - 5 Months</td>
                  <td id="4-5months-interest-rate-low-tier">See above</td>
                  <td id="4-5months-interest-rate-high-tier">Listed CD</td>
                  <td id="4-5months-renewal-term">Specials</td>
                </tr>
                {buildCdRow(filterCdRecords(cds, "6 - 11 Months", "6 - 11 Months"))}
                {buildCdRow(filterCdRecords(cds, "12 - 17 Months", "12 - 17 Months"))}
                {buildCdRow(filterCdRecords(cds, "18 - 23 Months", "18 - 23 Months"))}
                {buildCdRow(filterCdRecords(cds, "24 - 35 Months", "24 - 35 Months"))}
                {buildCdRow(filterCdRecords(cds, "36 - 47 Months", "36 - 47 Months"))}
                {buildCdRow(filterCdRecords(cds, "48 - 59 Months", "48 - 59 Months"))}
                {buildCdRow(filterCdRecords(cds, "60 Months", "60 Months"))}
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <FaqAccordion {...faqAccordionData} />
      <ContactInfo />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default CDs;
